import React from "react";
import L from "leaflet";
import MapFilterIcon from "./MapFilterIcons";
import {useMap} from "react-leaflet";

const MapFilterDropdownItem = ({position,type,title,number}) => {
    const map = useMap()
    return (
        <li
        >
            <button className="my-1 text-black flex items-center cursor-pointer text-left"
                onClick={() => {
                    // Set map view to the given position
                    map.panTo(new L.LatLng(position[0], position[1]))
                }}
            >
                <MapFilterIcon className="!w-[25px] flex-none !h-[25px]" content={number} type={type}/>{title}
            </button>

        </li>
    );
};

export default MapFilterDropdownItem;
