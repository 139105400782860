import React, { Children, cloneElement, isValidElement, useEffect, useRef, useState } from 'react'
import MapControll from '../MapControll'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons'
import childsHeight from '../../Utils/childsHeight'

const MapFilter = ({ position, children }) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const filterNav = useRef()

  useEffect(() => {
    if (isOpenFilter) {
      filterNav.current.style.height = childsHeight(filterNav.current) + 'px'
    } else {
      filterNav.current.style.overflow = 'hidden'
      filterNav.current.style.height = '0'
    }
  }, [isOpenFilter])

  /**
     * Pass isOpenFilter state and filterNav ref to all MapFilter child
     * @type {Array<Exclude<unknown, boolean | null | undefined>>}
     */
  const childrens = Children.map(children, child => {
    // Checking isValidElement is the safe way and avoids a TS error too.
    if (isValidElement(child)) {
      return cloneElement(child,
        {
          filterNav,
          isOpenFilter
        }
      )
    }

    return child
  })

  return (
        <MapControll className={`${isOpenFilter ? 'h-full' : 'h-full'} w-full sm:w-auto`} position={position}>
            <div className="pointer-events-auto h-full overflow-hidden rounded-tl-3xl">
                <button className="w-full" onClick={() => setIsOpenFilter(!isOpenFilter)} >
                    <div className="bg-black py-3 px-4 text-white flex items-center justify-between">
                        <span className='font-gaksans text-2xl font-light uppercase'>Filtres</span>
                        <FontAwesomeIcon icon={isOpenFilter ? faTimes : faChevronDown } />
                    </div>
                </button>
                <div onTransitionEnd={(e) => {
                  if (isOpenFilter) { e.currentTarget.style.overflow = 'auto' } else { e.currentTarget.style.overflow = 'hidden' }
                }} ref={filterNav} className={'max-h-[calc(100%-52px)] bg-offwhite-2 transition-all overflow-hidden'}>
                    {
                        childrens
                    }
                </div>
            </div>
        </MapControll>
  )
}

export default MapFilter
