import React from "react";
import {MapContainer, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import iconRetinaUrl from "leaflet/dist/images/marker-icon-2x.png"
import iconUrl from "leaflet/dist/images/marker-icon.png"
import shadowUrl from "leaflet/dist/images/marker-shadow.png"
import L from "leaflet";
import {isBrowser} from "../../utils/isBrowser";

// Re-import base leaflet icon
if(isBrowser()){
    L.Marker.prototype.options.icon = new L.Icon({
        iconUrl: iconUrl,
        iconRetinaUrl: iconRetinaUrl,
        shadowUrl: shadowUrl,
        iconSize: [25,41],
        iconAnchor: [25,41],
        popupAnchor: [0,-41],
    });
}

// markup
const Map = ({children, zoom, x, y, maxZoom, minZoom}) => {
    return (
        <MapContainer tap={false} zoomControl={false} className="md:h-680 h-[60vh] z-0 !rounded-3xl" center={[x ? x : 0, y ? y : 0]} minZoom={minZoom} maxZoom={maxZoom} zoom={zoom ? zoom : 13} scrollWheelZoom={false}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {children}
        </MapContainer>
    );
};

export default Map;
