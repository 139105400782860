import React from "react";
import {Marker} from "react-leaflet";
import MapPopup from "./MapPopup";
import MapMarkerIcon from "./MapMarkerIcons";

// markup
const MapMarker = ({lat, lng, type, name, description, url}) => {
    return (
        <Marker key={type+name} icon={MapMarkerIcon(type.slug,type.text)} position={[parseFloat(lat) ? parseFloat(lat) : 0, parseFloat(lng) ? parseFloat(lng) : 0]}>
            <MapPopup title={type.title} name={name} description={description} url={url}/>
        </Marker>
    );
};

export default MapMarker;
