/**
 * Calculate the height of an element
 * @param element the element
 * @param offsetItem this will be used to add height to the current childNodes height (in px)
 * @param offset this will be used to add height to the all height result (in px)
 * @returns {*}
 */
const childHeight = (element, offsetItem = 0,offset = 0) => {
    return [...element.childNodes].reduce(function(p, c) {return p + (c.offsetHeight || 0) + offsetItem;}, 0) + offset
};

export default childHeight;
