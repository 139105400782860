import React, { useCallback, useEffect, useState } from 'react'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import { graphql } from 'gatsby'
import Map from '../../components/Map'
import MapMarker from '../../components/Map/MapMarker'
import slugify from 'slugify'
import MapFilter from '../../components/Map/MapFilter/MapFilter'
import { ZoomControl } from 'react-leaflet'
import MapFilterItem from '../../components/Map/MapFilter/MapFilterItem'
import ReactPortal from '../../components/Utils/ReactPortal'
import MapFilterDropdown from '../../components/Map/MapFilter/MapFilterDropdown'
import MapFilterDropdownItem from '../../components/Map/MapFilter/MapFilterDropdownItem'
import { isBrowser } from '../../utils/isBrowser'
import FestifHelmet from '../../components/Helmet/FestifHelmet'

// markup
const CartePage = ({ data }) => {
  const metaTitle = 'Carte des sites | Le Festif! de Baie-Saint-Paul'
  const metaDescription = 'Consultez notre carte interactive et repérez en un clin d’œil l’emplacement des sites de spectacles, restaurants, stationnements et plus encore!'
  const metaKeywords = 'festival, musique, événement, spectacles, québec, charlevoix, baie-saint-paul, sites, emplacement, carte'

  // graphql data
  const filters = data.filters.group

  // Définir l'ordre arbitraire des catégories
  const order = [
    'Scènes',
    "Lieux d'animation",
    'Camping',
    'Navettes',
    "Stations d'eau potable",
    'Toilettes',
    'Dépôts de mégots',
    'Baiecycle',
    'Stations de recharge',
    'Stationnements',
    'Bouffe',
    'Services',
    'Services pour personne en situation de handicap'
  ]

  // Fonction de comparaison pour le tri
  function orderFunction (a, b) {
    const categorieA = a.kind[0]
    const categorieB = b.kind[0]
    const indexA = order.indexOf(categorieA)
    const indexB = order.indexOf(categorieB)

    if (indexA < indexB) {
      return -1
    }
    if (indexA > indexB) {
      return 1
    }
    return 0
  }

  // Trier les objets selon la catégorie
  filters.sort(orderFunction)

  // places history
  const placesHistory = data.places.edges
  const [places, setPlaces] = useState(placesHistory)

  const [activeFilters, setActiveFilters] = useState([])

  const [zoomControlPosition, setZoomControlPosition] = useState('topright')

  /**
   * Add or remove filter from the activeFilters if a filter checkbox is checked
   * @type {(function(*, *): void)|*}
   */
  const handleFilter = useCallback(
    (checked, filter) => {
      if (!checked) {
        setActiveFilters([...activeFilters, filter])
      } else {
        setActiveFilters(activeFilters.filter((v) => v !== filter))
      }
    },
    [activeFilters]
  )

  useEffect(() => {
    /**
     * Filter the places list with activeFilter list
     * every time activeFilter is updated
     */
    setPlaces(
      placesHistory.filter(({ node }) => {
        const { kind } = node.data
        return !activeFilters.includes(kind)
      })
    )

    /**
     * Change zoom control position on mobile
     */
    function handleResize () {
      if (!window.matchMedia('(min-width: 640px)').matches) {
        setZoomControlPosition('bottomleft')
      } else {
        setZoomControlPosition('topright')
      }
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return function cleanup () {
      window.removeEventListener('resize', handleResize)
    }
  }, [activeFilters, placesHistory])
  if (isBrowser()) {
    return (
      <Wolflayout mobileTitle={'Infos'}>
        <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
        <InfosNavigation />
        <InfosHeader
          title='Carte'
        />
        <div className="content pb-12 relative container px-0 mx-auto">
          <Map
            x={47.44116102945997}
            y={-70.50551966137529}
            minZoom={15}
            zoom={16}
          >
            {places &&
              places.map(({ node }, i) => {
                const { address, kind, title, number } = node.data
                const { uid, position } = node
                return (
                  <MapMarker
                    key={i}
                    name={title.text}
                    description={address.text}
                    lat={position?.latitude}
                    lng={position?.longitude}
                    type={{
                      slug: slugify(kind, {
                        replacement: '',
                        lower: true,
                        trim: true,
                        strict: true
                      }),
                      title: kind,
                      text: number
                    }}
                    url={{
                      internal: `/place/${uid}`
                    }}
                  />
                )
              })}
            <ZoomControl position={zoomControlPosition} />
            <ReactPortal id="map-filter">
              <MapFilter position="topleft" className="!ml-0 !pl-0">
                {filters &&
                  filters.map(({ kind, number, edges }, i) => {
                    const filter = kind[0]
                    const haveNumber = number
                    const places =
                      edges &&
                      edges.filter(({ node }) => {
                        const { number } = node.data
                        return number
                      })

                    return (
                      <MapFilterItem
                        key={`FilterItem-${i}`}
                        checked={!activeFilters.includes(filter)}
                        callback={handleFilter}
                        title={filter}
                        type={slugify(filter, {
                          replacement: '',
                          lower: true,
                          trim: true,
                          strict: true
                        })}
                      >
                        {haveNumber
                          ? (
                          <MapFilterDropdown>
                            {places &&
                              places.map(({ node }, i) => {
                                const {
                                  kind,
                                  title,
                                  number
                                } = node.data
                                const { position } = node
                                return (
                                  <MapFilterDropdownItem
                                    key={`DropdownItem-${i}`}
                                    title={title.text}
                                    type={slugify(kind, {
                                      replacement: '',
                                      lower: true,
                                      trim: true,
                                      strict: true
                                    })}
                                    number={number}
                                    position={[position?.latitude, position?.longitude]}
                                  />
                                )
                              })}
                          </MapFilterDropdown>
                            )
                          : null}
                      </MapFilterItem>
                    )
                  })}
              </MapFilter>
            </ReactPortal>
          </Map>
          <div id="map-filter" />
        </div>
      </Wolflayout>
    )
  }
  return null
}

export default CartePage

export const query = graphql`
  query {
    filters: allPrismicPlaces(sort: { fields: data___number }) {
      group(field: data___kind) {
        edges {
          node {
            position {
              latitude
              longitude
            }
            data {
              title {
                text
              }
              number
              kind
            }
          }
        }
        kind: distinct(field: data___kind)
        number: distinct(field: data___number)
      }
    }
    places: allPrismicPlaces {
      edges {
        node {
          uid
          position {
            latitude
            longitude
          }
          data {
            address {
              text
            }
            description {
              html
            }
            image {
              gatsbyImageData
              alt
            }
            kind
            number
            title {
              text
            }
          }
        }
      }
    }
  }
`
