import React from 'react'

const filterIcons = {
  toilettes: 'bg-size-toilette bg-map-toilette bg-image-toilette',
  scenes: 'bg-size-scene bg-map-scene bg-image-scene',
  billetterie: 'bg-size-ticket bg-map-ticket bg-image-ticket',
  lieuxdanimation: 'bg-size-animation bg-map-animation bg-image-animation',
  stationnements: 'bg-size-parking bg-map-parking bg-image-parking',
  bouffe: 'bg-size-food bg-map-food bg-image-food',
  camping: 'bg-size-hebergement bg-map-camping bg-image-camping',
  stationsdeaupotable: 'bg-size-water bg-map-water bg-image-water',
  depotsdemegots: 'bg-size-disposal bg-map-disposal bg-image-disposal',
  zonedombreetdefraicheur: 'bg-size-cool bg-map-cool bg-image-cool',
  baiecycle: 'bg-size-food bg-map-baiecycle bg-image-baiecycle',
  services: 'bg-size-food bg-map-recharge bg-image-recharge',
  navettes: 'bg-size-food bg-map-navette bg-image-navette',
  servicespourpersonneensituationdehandicap: 'bg-size-ticket bg-map-accessibility bg-image-accessibility'
}

/**
 * Get icon style of the given type
 * @param className
 * @param type
 * @param content
 * @returns {JSX.Element}
 * @constructor
 */
const MapFilterIcon = ({ className, type, content = '' }) => {
  return (
        <div className={`${filterIcons[type]} mr-3 !flex items-center justify-center text-white font-bold text-xs font-ohno bg-center rounded-full !w-7 !min-w-7 !h-7 !min-h-7 bg-no-repeat  ${className}`}>
            {content}
        </div>
  )
}

export default MapFilterIcon
