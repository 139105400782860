import React from "react";

const MapControll = ({position,className,children,ref}) => {
    const POSITION_CLASSES = {
        bottomleft: 'leaflet-bottom leaflet-left',
        bottomright: 'leaflet-bottom leaflet-right',
        topleft: 'leaflet-top leaflet-left',
        topright: 'leaflet-top leaflet-right',
    }

    return (
        <div ref={ref} className={`${POSITION_CLASSES[position]} !z-10 ${className}`}>
            {children}
        </div>
    );
};

export default MapControll;
