import React from "react";

const MapFilterDropdown = ({children}) => {
    return (
        <ul className="font-gaksans text-sm font-bold !px-0 !mx-0 !list-none">
            {
                children
            }
        </ul>
    );
};

export default MapFilterDropdown;
