import L from 'leaflet'

const markerIcons = {
  toilettes: 'bg-size-toilette bg-map-toilette bg-image-toilette',
  scenes: 'bg-size-scene bg-map-scene bg-image-scene',
  billetterie: 'bg-size-ticket bg-map-ticket bg-image-ticket',
  lieuxdanimation: 'bg-size-animation bg-map-animation bg-image-animation',
  stationnements: 'bg-size-parking bg-map-parking bg-image-parking',
  bouffe: 'bg-size-food bg-map-food bg-image-food',
  camping: 'bg-size-hebergement bg-map-camping bg-image-camping',
  stationsdeaupotable: 'bg-size-water bg-map-water bg-image-water',
  depotsdemegots: 'bg-size-disposal bg-map-disposal bg-image-disposal',
  zonedombreetdefraicheur: 'bg-size-cool bg-map-cool bg-image-cool',
  baiecycle: 'bg-size-food bg-map-baiecycle bg-image-baiecycle',
  services: 'bg-size-food bg-map-recharge bg-image-recharge',
  navettes: 'bg-size-food bg-map-navette bg-image-navette',
  servicespourpersonneensituationdehandicap: 'bg-size-ticket bg-map-accessibility bg-image-accessibility'
}

/**
 * Get the marker icon style of the given type
 * @param type
 * @param content
 * @returns {*}
 * @constructor
 */
const MapMarkerIcon = (type, content = '') => {
  return (
    new L.DivIcon({
      iconAnchor: [13, 2],
      className: `${type} ${markerIcons[type]} !flex items-center text-[#fff6dd] justify-center font-bold font-ohno bg-center rounded-full bg-no-repeat !w-7 !h-7`,
      html: content || ''
    })
  )
}

export default MapMarkerIcon
